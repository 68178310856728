
/* Swiper js styles */
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img  {
    object-fit: contain;
  }
  .swiper-pagination-bullet-active {
    background-color: #00d285;
  }
  .swiper-button-next, .swiper-button-prev {
    background-color: rgba(31, 32, 32, 0.473);
    padding: .4rem;
    border-radius: 222rem;
    height: 4rem;
    width: 4rem;
  }
  
  .swiper-button-prev:after, .swiper-button-next:after{
    color: white;
    font-size: 1.6rem;
  }

  .swiper-button-disabled {
    display: none;
  }