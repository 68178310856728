.sectionFeedback {
    height: 100vh;
}

.feedBackCont {
    background-color: #fafaff;
    padding: 4.5rem 6.5rem;
    width: 80%;
    box-shadow: 1rem 1rem 2rem 0 rgba(0, 0, 0, 0.35);
}

.feedbackForm {
    width: 50%;
}

.feedbackForm input, .feedbackForm textarea{
    background-color: #fff;
    border-radius: .5rem;
    border: 1px solid #ddd;
    padding: 1.2rem 1.8rem;
    width: 100%;
    font-size: 1.6rem;
}

.feedbackForm button{
    background-color: #00d285;
    color: #fff;
    padding: 1.2rem 1.2rem;
    border-radius: .4rem;
}
.sectionFeedback button:hover,
.sectionFeedback button:active{
    background-color: #33db9d;
    color: #fff;
}

.feedBackDiscription {
    width: 50%;
}
.feedBackDiscription h2 {
   font-size: 3.8rem;
}
.feedBackDiscription p {
   font-size: 1.3rem;
   color: #666;
}
.feedBackDiscription button {
   font-size: 1.3rem;
   color: #fff;
   padding: 1.2rem 1.4rem;
   width: 60%;
   border-radius: .4rem;
   background-color: #00d285;
}