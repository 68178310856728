.loginCateCont {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.2rem;
    padding: 6rem;
}

.loginCateCont .cateCard {
    border-radius: 1.2rem;
    background-color: #999;
    color: #fff;
    padding: 1.2rem;
    font-size: 1.4rem;
    height: 25rem;
    padding: 2.8rem;
    font-size: 4.5rem;
}


.sectionLogin.sectionMDLogin  {
    background-image: linear-gradient(rgba(209, 207, 207, 0.24), rgba(49, 56, 49, 0.13)), 
    url(./publicImages/bbbg.webp);
}
.sectionMDLogin .loginDiscription{
    background-image: none;
    background-color: rgba(24, 22, 22, 0.561);

    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px); 
}
.sectionMDLogin .loginForm {
    background-color: transparent;
    color: #fff;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.sectionMDLogin .loginForm button, .sectionMDLogin .loginForm input{
    background-color: transparent;
    color: #fff;
}
.sectionMDLogin .loginForm button, .sectionMDLogin .loginForm input{
    color: #fff;
}



.sectionMDLogin .loginForm button:hover, 
.sectionMDLogin .loginForm button:active {
    background-color: #fff;
    color: #666;
}