/* ____________________________________________Filters Decoration Blocks */

.filterSearchCont {
    background-color: antiquewhite;
    border-radius: 1.2rem; 
    overflow: hidden;
    padding-left: 2.8rem;
}


.filterSearchCont input, .filterSearchCont select, .filterSearchCont button{
    padding: .6rem;
    font-size: 1.4rem;
    color: #999;
    background-color: transparent;
}

.filterSearchCont input{
    width: 90%;
    border-left: 1px solid #999;
    height: 100%;
    padding: 1.2rem;
}
.filterSearchCont button{
    color: #fff;
    background: linear-gradient(270deg,#ff9417 -7.62%,#ffbd40 91.9%);
    padding: 1.2rem 2.8rem; 
}
.filterSearchCont button:hover,
.filterSearchCont button:active {
   color: #fff;
   background: none;
   background-color: #00d285;
}
.filtersCardsCont {
    padding: 2.4rem 0;
    color: #999;
}
.filtersCard {
    background-color: #fafaff;
    color: #472702;
    border:  1px solid #ffbd40;
    border-radius: 1.2rem;
    overflow: hidden;
    font-size: 1.2rem;
    padding: 2.4rem;
}
.priceAcc {
    font-size: 1.2rem;
    font-weight: 900; 
    color: #fff;
    background: linear-gradient(270deg,#ff9417 -7.62%,#ffbd40 91.9%);
    padding: 0.5rem 0.9rem;
    border-radius: .9rem;
}

/* _______________________________________________ Busery Decoration Blocks */
.buserySearchCont {
    background-color: #fafaff;
    border-radius: 1.2rem; 
    overflow: hidden;
    padding-left: 2.8rem;
    box-shadow: 1.1rem 1.1rem 2rem -1.2rem rgb(0 0 0 / 35%);
}


.buserySearchCont input, .buserySearchCont select, .buserySearchCont button{
    padding: .6rem;
    font-size: 1.4rem;
    color: #999;
    background-color: transparent; 
}

.buserySearchCont input{
    width: 90%;
    border-left: 1px solid #999;
    height: 100%;
    padding: 1.2rem;
}
.buserySearchCont button{
    color: #fff;
    background: linear-gradient(270deg,#ff9417 -7.62%,#ffbd40 91.9%);
    padding: 1.2rem 2.8rem; 
}
.buserySearchCont button:hover,
.buserySearchCont button:active {
   color: #fff;
   background: none;
   background-color: #00d285;
}
.buserysCardsCont {
    padding: 2.4rem 0;
    color: #999;
}
.buseryCard {
    background-color: #fafaff;
    color: #472702;
    border:  1px solid #ffbd40;
    border-radius: 1.2rem;
    overflow: hidden;
    font-size: 1.2rem;
    padding: 2.4rem;
}
.approvedRequest .buseryCard {
    background-color: #fafaff;
    color: #472702;
    border:  1px solid #00d285;
    border-radius: 1.2rem;
    overflow: hidden;
    font-size: 1.2rem;
    padding: 2.4rem;
}
.pendingRequest .buseryCard {
    background-color: #fafaff;
    color: #090247;
    border:  1px solid #4d40ff;
    border-radius: 1.2rem;
    overflow: hidden;
    font-size: 1.2rem;
    padding: 2.4rem;
}
.Rejected .buseryCard {
    background-color: #fafaff;
    color: #47020b;
    border:  1px solid #ff4059;
    border-radius: 1.2rem;
    overflow: hidden;
    font-size: 1.2rem;
    padding: 2.4rem;
}
.priceAcc {
    font-size: 1.2rem;
    font-weight: 900; 
    color: #fff;
    background: linear-gradient(270deg,#ff9417 -7.62%,#ffbd40 91.9%);
    padding: 0.5rem 0.9rem;
    border-radius: .9rem;
}



.BuseryBtn button,
.BuseryBtn span {
    width: 16rem;
    text-align: center;
    padding: .6rem 1.4rem; 
    border-radius: .6rem; 
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

}