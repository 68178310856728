.sectionPoliceExams {
  height: 100vh;
  position: relative;
}

.examsPage {
  height: 100%;
}
.verifyingPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 2.4rem;
  color: #fff;
}

.verifyingPage figure{
  padding: 3.4rem;
  width: 20rem;
  height: 20rem;
  border-radius: 9999rem;
  background-color: rgba(255, 255, 255, 0.388);
  color: #fff;
}

.verifyingPage.ArmyVerf {
  background-image: linear-gradient(
      rgba(10, 10, 10, 0.404),
      rgba(70, 11, 11, 0.438)
    ),
    url(../../../cbtimages/bbb.jpg);
}
.verifyingPage.NavyVerf {
  background-image: linear-gradient(
    rgba(10, 10, 10, 0.404),
    rgba(70, 11, 11, 0.438)
    ),
    url(../../../cbtimages/navals.webp);
}
.verifyingPage.AirforceVerf {
  background-image: linear-gradient(
    rgba(10, 10, 10, 0.404),
    rgba(70, 11, 11, 0.438)
    ),
    url(../../../cbtimages/nafs.webp);
}
.verifyingPage.PoliceVerf {
  background-image: linear-gradient(
    rgba(10, 10, 10, 0.404),
    rgba(8, 4, 4, 0.438)
    ),
    url(../../../cbtimages/asf.avif); 
  background-size: cover; 
}
.verifyingPage.NECOVerf {
  background-image: linear-gradient(
    rgba(10, 10, 10, 0.404),
      rgba(70, 11, 11, 0.438)
    ),
    url(../../../cbtimages/ict.jpg);
}
.verifyingPage.JAMBVerf {
  background-image: linear-gradient(
    rgba(10, 10, 10, 0.404),
    rgba(11, 70, 45, 0.438)
    ),
    url(../../../cbtimages/jambs.jpg);
}
.verifyingPage.WAECVerf {
  background-image: linear-gradient(
    rgba(10, 10, 10, 0.404),
    rgba(70, 11, 11, 0.438)
    ),
    url(../../../cbtimages/bbb.jpg);
}

.verifyingPage aside {
  background-color: transparent;
  width: 40rem;
  border-radius: 0.8rem;
  padding: 2.4rem;
}

.verifyingPage aside input[type="password"] {
  padding: 1.2rem 2.1rem;
  font-size: 1.8rem;
  color: #fff;
  width: 100%;
  background-color: rgba(51, 51, 51, 0.89);
  border-radius: .8rem;
  border: none;
  border-bottom: 4.5px solid #2d5ecf;
  font-weight: 100;
}

 .verifyingPage.JAMBVerf aside input[type="password"]{
  border-bottom: 4.5px solid #04aa6d;

}

 .verifyingPage.JAMBVerf aside input[type="submit"]{
  background-color:  #04aa6d; 
}

.verifyingPage aside input[type="submit"] {
  background-color: #2d5ecf;
  color: #fff;
  font-weight: 600;
  width: 100%;
  padding: 1.2rem 2.1rem;
  border-radius: 333.8rem;
  cursor: pointer;
}
.verifyingPage aside input[type="submit"]:hover,
.verifyingPage aside input[type="submit"]:active {
  background: #466ecc;
}

.ex_header {
  padding: 1.2rem 2.3rem;
  color: #fff;
  font-size: 1.6rem;
}
.ex_header button{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 1.2rem;
  border-radius: 0.5rem;
  color: #fff;
  background-color: transparent;
}
.ex_header button:hover,
.ex_header button:active{
  background-color: rgba(156, 153, 153, 0.411);
  
}

.ArmyE .ex_header {
  background-image: linear-gradient(
      to right bottom,
      rgba(130, 60, 48, 0.432),
      rgba(205, 177, 140, 0.425)
    ),
    url(../cbtImage/army1.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  /* background-image: linear-gradient(
      to right ,
      rgba(130, 60, 48, 0.877),
      rgba(205, 177, 140, 0.87)
    ),
    url(../cbtImage/army3.jpg); */
}
.NavyE .ex_header {
  background-image: linear-gradient(
      to right,
      rgba(13, 2, 43, 0.959),
      rgba(46, 31, 112, 0.877)
    ),
    url(../cbtImage/navy1.jpg);
  background-size: cover;
}
.AirforceE .ex_header {
  background-color: #282a35;
  background-image: linear-gradient(
      to right bottom,
      rgba(46, 58, 42, 0.568),
      rgba(56, 61, 30, 0.411)
    ),
    url(../cbtImage/airforce1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff4a3;
}
.PoliceE .ex_header {
  background-color: #282a35;
}
.JambE .ex_header {
  background-image: linear-gradient( to right bottom, rgb(29 155 149), #04aa6d );
}


.ex_contents {
  height: 100%;
  padding: 2.4rem 3.4rem;
  /* background-color: #f3f3f3; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: #161313;
  font-size: 1.5rem;
}

.ex_contents.na {
  background-image: linear-gradient(
      rgba(243, 243, 243, 0.911),
      rgba(221, 221, 221, 0.918)
    ),
    url(../../../cbtimages/nig_army.png);
}
.ex_contents.nn {
  background-image: linear-gradient(
      rgba(243, 243, 243, 0.911),
      rgba(221, 221, 221, 0.918)
    ),
    url(../../../cbtimages/navy.png);
}
.ex_contents.naf {
  background-image: linear-gradient(
      rgba(243, 243, 243, 0.911),
      rgba(221, 221, 221, 0.918)
    ),
    url(../../../cbtimages/airforce.png);
}
.ex_contents.npf {
  background-image: linear-gradient(
      rgba(243, 243, 243, 0.911),
      rgba(221, 221, 221, 0.918)
    ),
    url(../../../cbtimages/police.png);
}
.ex_contents.neco {
  background-image: linear-gradient(
      rgba(243, 243, 243, 0.911),
      rgba(221, 221, 221, 0.918)
    ),
    url(../../../cbtimages/neco.png);
}
.ex_contents.waec {
  background-image: linear-gradient(
      rgba(243, 243, 243, 0.911),
      rgba(221, 221, 221, 0.918)
    ),
    url(../../../cbtimages/waec.png);
}
.ex_contents.jamb {
  background-image: linear-gradient(
      rgba(243, 243, 243, 0.911),
      rgba(221, 221, 221, 0.918)
    ),
    url(../../../cbtimages/jamb.png);
}

.qAndA {
  width: 95%;
  height: 20rem;
  justify-content: space-between;
}

.activeQues {
  width: 20%;
  background-color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 0.5rem;
  padding: 0.6rem;
}
.activeQues + p {
  font-size: 2.8rem;
  font-weight: 100;
}

.theQuerry {
  width: 86%;
  font-size: 2.8rem;
  font-weight: 100;
  height: 5.6rem;
}

.QuestionBtn{
  width: 15.2rem;
    padding: 1.2rem 2.4rem;
    border-radius: 222rem;
    color: #fff;
    background-color: #008cba;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    /* outline: 2px solid #008cba;
    outline-offset: 2px; */
}

.next.QuestionBtn {
  background-color: #04aa6d;
  color: #fff;
  /* outline: 2px solid #04aa6d; */
}
.paginationsItems {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  width: 64%;
}

.paginationsItems figure {
  width: 3.7rem;
  height: 2.5rem;
  padding: 0.5rem;
  background-color: #282a35;
  /*  */
  color: #fff;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 600;
  transition: all 0.3s;
  outline: 1px solid #e66161;
  outline-offset: 2px;
}

.paginationsItems figure:hover,
.paginationsItems figure:active {
  background-image: none !important;
  background-color: #fff;
  color: #24275e;
  outline: 1px dashed #e66161;
  outline-offset: 4px;
}
.ArmyE .paginationsItems figure {
  background-image: linear-gradient(
      to right bottom,
      rgba(46, 58, 42, 0.568),
      rgba(56, 61, 30, 0.411)
    ),
    url(../cbtImage/army4.jpg);
}
.NavyE .paginationsItems figure {
  background-image: linear-gradient(
      to right bottom,
      rgba(45, 46, 126, 0.712),
      rgba(35, 30, 61, 0.904)
    ),
    url(../cbtImage/navy2.jpg);
  background-size: cover;
}
.AirforceE .paginationsItems figure {
  background-image: linear-gradient(
      to right bottom,
      rgba(46, 58, 42, 0.568),
      rgba(56, 61, 30, 0.411)
    ),
    url(../cbtImage/airforce2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.JambE .paginationsItems figure {
  outline: 1px solid #999262;
  background-color: #1d4489;
 
}

.quizeCont  .paginationContEx .pagiNationTag.checked{
  background-image: none;
  background-color: #e66161;
  border: none !important;

}
/* .PoliceE .paginationsItems figure {
  background-image: linear-gradient(
      to right bottom,
      rgba(46, 58, 42, 0.568),
      rgba(56, 61, 30, 0.411)
    ),
    url(../cbtImage/army4.jpg); 
} */
.paginationsItems figure.activeExPg {
  /* background-color: #ffc0c7; */
  background-color: #c561e6;
  background-image: none;
  color: #1b5c0a;
}

.ex_navi span {
  width: 15.2rem;
  padding: 1.2rem 2.4rem;
  border-radius: 222rem;
  color: #fff;
  background-color: #008cba;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
}

.ex_navi span:hover,
.ex_navi span:active {
  opacity: 0.7;
}

.ex_navi span:nth-child(2) {
  background-color: #04aa6d;
  color: #fff;
}
.ex_navi span a {
  color: #fff;
}

input [name="optionEx"] {
  transform: scale(3);
  color: red;
}

.ex_subject {
  width: 18rem;
  padding: 0.8rem 1rem;
  border-radius: 818rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  background-color: #282a35;
  color: #fff;
}

.AirforceE .ex_subject {
    border-radius: .5rem;
}
.JambE .ex_subject {
    border-radius: .2rem;
    background-color: #04aa6d;
}

.ex_subject.sujectActive {
  background-color: #fff4a3;
  color: #333;
  box-shadow: 0.4rem 0.2rem 1rem -0.4rem rgba(0, 0, 0, 0.35);
}

.ex_submitt h2 {
  font-size: 2.7rem;
  font-weight: 400;
  line-height: 1.2;
}
.ex_submitt h2 span {
  color: #2d5ecf;
}

.sub_ex_disc span {
  border-radius: 0.8rem;
  background-color: #c6d0e5;
  color: #2d5ecf;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 3.8rem;
  height: 3.8rem;
}

.sub_ex_disc p {
  width: 90%;
}

.ex_submitBtn {
  border-radius: 9.4rem;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: #2d5ecf;
  color: #fff;
  width: 18rem;
  padding: 1.2rem 1.4rem;
  outline: 2px solid #2d5ecf;
  outline-offset: 4px;
}
.ex_submitBtn:hover,
.ex_submitBtn:active {
  background-color: #e97d76;
  color: #fff;
  outline: 2px solid #e97d76;
}

.mainConts {
  display: grid;
  height: 100%;
  grid-template-columns: 3fr 1.4fr;
  background-color: #b5bed3;
  background-image: linear-gradient(
    rgba(243, 243, 243, 0.911),
    rgba(221, 221, 221, 0.918)
  );
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.ex_status {
  padding: 2.4rem;
  border-left: 1px dashed #aaa;
}

.ex_clockCont {
  background-image: linear-gradient(
    to right bottom,
    rgb(155, 29, 29),
    rgb(0, 0, 68)
  );
  color: #fff;
  border-radius: 1.2rem;
  padding: 1.2rem 2.4rem;
}

.ArmyE .ex_clockCont {
  /* background-image: linear-gradient(to right bottom, rgb(46, 58, 42), rgb(56, 61, 30) );  */
  background-image: linear-gradient(
      to right bottom,
      rgba(130, 60, 48, 0.432),
      rgba(205, 177, 140, 0.425)
    ),
    url(../cbtImage/army4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.NavyE .ex_clockCont {
  background-image: linear-gradient(
      to right bottom,
      rgba(46, 58, 42, 0.295),
      rgba(56, 61, 30, 0.22)
    ),
    url(../cbtImage/navy2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.AirforceE .ex_clockCont {
  background-image: linear-gradient(
      to right bottom,
      rgba(53, 54, 53, 0.295),
      rgba(255, 255, 255, 0.22)
    ),
    url(../cbtImage/airforce2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.PoliceE .ex_clockCont{ 
    background-image: linear-gradient(
        to right bottom,
        rgba(46, 58, 42, 0.295),
        rgba(56, 61, 30, 0.22)
      ),
      url(../../armyUni.jpg);
       background-size: cover;
      background-repeat: no-repeat;
}
.JambE .ex_clockCont{ 
   background-image: linear-gradient( to right bottom, rgb(29 155 149), #04aa6d );
}

.ex_hour {
  font-size: 6.4rem;
  font-weight: 700;
}

.ex_sec {
  font-size: 1.4rem;
  font-weight: 600;
  justify-content: space-between;
}

.ex_date {
  font-size: 1.6rem;
  font-weight: 600;
}

/* Modal box decoration bock */
.sectionSub {
  background-color: rgba(0, 0, 0, 0.34);
  height: 100%;
  width: 100%;
  z-index: 99999;
  position: absolute;
  transition: all 0.8s;
}

.confirmSub {
  background-color: #ffe8da;
  text-align: center;
  color: #5d322f;
  padding: 2.4rem;
  border-radius: 1.2rem;
  width: 35%;
  font-size: 4.5rem;
  border-left: 6px solid #e97d76;
}

.confirmSub button {
  padding: 0.8rem 2.4rem;
  color: #fff;
  background-color: #04aa6d;
  border-radius: 0.4rem;
  border: none;
  transition: all 0.3s;
}

.confirmSub button:hover,
.confirmSub button:active {
  opacity: 0.7;
  color: #fff;
}

.confirmSub button:first-child {
  background-color: rgb(184, 52, 52);
}


.cbtLoginCTA {
  width: 45%;
  padding: 3.4rem;
  border-radius: 1.2rem;
  border-left: .8rem solid #008cba;
  border-bottom: .3rem solid #008cba;
  font-size: 1.8rem;
  background-color: #b8d0d8;
}

/* rem and em do NOT depend on html font-size in media queries! Instead, 1rem = 1em = 16px */



/**************************/
/* ABOVE 1181px (Computer) */
/**************************/

@media (min-width: 74em) {
  .ex_header {
    height: 8rem;
    align-items: center; 
  }
}
/**************************/
/* BELOW 1310px (Smaller desktops) */
/**************************/

@media (max-width: 82em) {
  .mainConts { 
    grid-template-columns: 3fr 1.6fr; 
}
}
/**************************/
/* BELOW 1181px (Computer) */
/**************************/

@media (max-width: 74em) {
  .ex_submitt h2 {
    font-size: 2rem; 
}
}
/**************************/
/* BELOW 1140px (Computer) */
/**************************/

@media (max-width: 71em) {
  .paginationsItems figure {
    width: 3.3rem;
    height: 2.1rem;
  }

 
  
}

/**************************/
/* BELOW 1080px (Computer) */
/**************************/

@media (max-width: 67em) {
  .paginationsItems { 
    grid-template-columns: repeat(12, 1fr);
    width: 64%;
}

.ex_subject {
  width: 13rem;
  padding: 0.8rem 1rem; 
  font-weight: 300; 
  font-size: 1.3rem;
}

.QuestionBtn {
  width: 11.2rem;
  padding: 1rem 2rem;
}


* > * {
  font-family: 'Poppins', sans-serif;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
}
user agent stylesheet
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.quizeCont {
  position: relative;
  height: 100%;
  color: #333;
  font-size: 1.5rem;
  /* margin: 40px auto; */
  /* width: 60%; */
}
.ex_contents {
  height: 100%;
  padding: 2.4rem 3.4rem;
  /* background-color: #f3f3f3; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: #161313;
  font-size: 1.5rem;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  overflow-x: hidden;
}
:root {
  --primary-color: #0DFF92;
  --dark-color: #222222;
  --light-color: #f0f0f0;
}
:root {
  --swiper-navigation-size: 44px;
}
:root {
  --swiper-theme-color: #007aff;
}
.theQuerry {
  width: 86%;
  font-size: 1.8rem; 
  margin-top: 1rem;
}

}





/**************************/
/* BELOW 950px (Computer) */
/**************************/ 
@media (max-width: 59.6em) {
  .paginationsItems figure {
    width: 3rem;
    height: 2.1rem;
}
.paginationsItems figure {
  font-size: 1.2rem;
  font-weight: 300;
}

.activeQues {
  width: 30%;
   
}

.ex_hour {
  font-size: 4.9rem;
  font-weight: 500;
}

.ex_clockCont {
  gap: 0rem;
}
.quizeCont ul {
  gap: .8rem;
}
}


@media (max-width: 56.56em) {
  .sub_ex_disc p {
    width: 100%; 
}
  
}



/**************************/
/* BELOW 856px (Tablets) */
/**************************/ 
@media (max-width: 53.5em) {
  .paginationsItems figure {
    width: 2.7rem;
    height: 1.8rem;
}

.theQuerry {
  width: 100%;
  font-size: 1.6rem;
}
  
}
/**************************/
/* BELOW 806px (Tab) */
/**************************/ 
@media (max-width: 53.5em) {
  .forceSelect a p{
    display: none;
  }
  
}



/**************************/
/* BELOW 665px (Phones) */
/**************************/ 
@media (max-width: 42em) {
  .sub_ex_disc {
    font-size: 1.1rem;
  }

  .ex_submitt h2 {
    font-size: 1.6rem;
}
.sub_ex_disc span {
  border-radius: 0.8rem;  
  padding: 0.2rem;
  width: 2.8rem;
  height: 2.8rem;
}

.paginationsItems {
  grid-template-columns: repeat(15, 1fr); 
}

.ex_contents { 
  padding: 1.4rem 1.4rem; 
  font-size: 1.5rem;
}

div.quizeCont ul li label {
  font-size: 1.4rem; 
}

.quizeCont .questions  ul li .check {
  height: 18px;
  width: 18px; 
}

.quizeCont .questions ul li .check::before { 
  height: 12px;
  width: 12px; 
}
.QuestionBtn {
  width: 10.2rem;
  padding: .6rem 1.4rem ;
  border-radius: 222rem; 
  font-weight: 300;
  font-size: 1.3rem;
}

.quizeCont .questions ul li {
  gap: 2.5rem;
}

.ex_submitBtn { 
  font-size: 1.1rem;
  font-weight: 300; 
  width: 10rem;
  padding: .6rem;  
  outline-offset: 2px;
  outline: 1px solid #2d5ecf;
}

.ex_clockCont {
  padding: .8rem;
}

.ex_hour {
  font-size: 3.5rem;
  font-weight: 300;
}

.ex_date {
  font-size: 1.1rem;
  font-weight: 300;
}

.ex_sec {
  font-size: 1.2rem;
  font-weight: 300; 
}

.ex_submitt {
  gap: 1rem;

}
.ex_submitt div {
  gap: .5rem;
}


.ex_submitt h2 {
    font-size: 1.5rem;
}

.ex_contents  {
  gap: 1rem;
}

.questions {
  gap: .2rem;
  margin-top: .6rem;
  margin-left: 1.2rem;
}

.quizeCont { 
  gap: 1.4rem;
}

.theQuerry {
  height: 4.2rem;
}

.ex_header {
  padding: 1rem 2.3rem; 
  font-size: 1.2rem;
}

.paginationsItems figure {
  font-size: 0.9rem;
  font-weight: 600;
  width: 2.5rem;
  height: 1.6rem;
}
 
 
.ex_status {
  padding:1.2rem 1.4rem ; 
  gap: 1.2rem;
}

}

/**************************/
/* BELOW px (Phone) */
/**************************/ 
@media (max-width: 37.56em) {
  .paginationsItems figure {
    font-size: 0.8rem;
    font-weight: 300;
    width: 2.3rem;
    height: 1.5rem;
}

.QuestionBtn {
  width: 8.2rem;
  font-size: 1.1rem;

}
}
/**************************/
/* BELOW 576px (Phones) */
/**************************/ 
@media (max-width: 36em) {
 

  .paginationsItems {
    grid-template-columns: repeat(12, 1fr);
}
.paginationsItems figure { 
  width: 2.5rem;
  height: 1.6rem;
}
}
/**************************/
/* BELOW 519px (Phones) */
/**************************/ 
@media (max-width: 32.43em) {
  .ex_submitt div { 
    text-align: center;
}

.sub_ex_disc span {
 display: none;
}
.ex_submitt div {
  justify-content: center;
}
}
/**************************/
/* BELOW 515px (Phones) */
/**************************/ 
@media (max-width: 32.1em) {
  .ex_subject {
    width: 10.6rem;
    padding: 0.8rem 0.9rem;
    font-weight: 300;
    font-size: 1.1rem;
}
}







/**************************/
/* BELOW 496px (Computer) */
/**************************/ 

@media (max-width: 31.6em) {
  .paginationsItems figure {
    width: 1.9rem;
    height: 1.6rem;
}
.sub_ex_disc span {
  display: block;
  padding: 0;
  
}
}
/**************************/
/* BELOW 460px (Computer) */
/**************************/ 

@media (max-width: 29em) { 
.sub_ex_disc span {
  display: none;  
}  
}

/**************************/
/* BELOW 452px (Computer) */
/**************************/ 

@media (max-width: 28.5em) {
  .mainConts {
    grid-template-columns: 3fr ;
}
  
  .ex_status {
    display: none;
}

.paginationsItems {
  grid-template-columns: repeat(15, 1fr);
}
.paginationsItems figure {
  width: 2.2rem;
  height: 1.6rem;
}
}


/**************************/
/* BELOW 418px (Computer) */
/**************************/ 

@media (max-width: 26.1em) {
  .paginationsItems {
    grid-template-columns: repeat(14, 1fr);
  }
}
 
/**************************/
/* BELOW 398px (Computer) */
/**************************/ 

@media (max-width: 24.3em) {
  .paginationsItems {
    grid-template-columns: repeat(13, 1fr);
  }
}
 
 
/**************************/
/* BELOW 362px (Computer) */
/**************************/ 

@media (max-width: 24.3em) {
  .paginationsItems {
    grid-template-columns: repeat(12, 1fr);
  }
}
 
/**************************/
/* BELOW 339px (Computer) */
/**************************/ 

@media (max-width: 21.1875em) {
  .paginationsItems {
    grid-template-columns: repeat(11, 1fr);
  }
}
 



