.instructorProfileCont {
  font-size: 1.5rem;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr 9fr;
  background-color: #fff;
}

.profileHeader {
  /* grid-column: span 2; */
  background-color: #fff;
  padding: 1.8rem;
}

.profileAside {
  position: relative;
  grid-row: span 2;
  background-color: #fafaff;
  padding: 1.8rem;
}

 
.itemsPf  img {
  width: 1.8rem;
  height:1.8rem;
}
.profBtn {
  width: 100%;
  padding: .7rem 1.4rem;
  border-radius: .2rem;
  background-color: #00d285;
  color: #fff;
  margin-bottom: 3.2rem;
}
.profileContents {
  font-size: 1.5rem;
}

.profileAsideSec {
  width: 100%;
  /* margin-top: 2.4rem; */
}

.active-prl-tab:link,
.active-prl-tab:visited {
  transition: var(--speed) ease;
  /* background-color: #2d5ecf; */
  border-radius: 0.4rem;
  color: #2d5ecf;
}

.prfLinksto {
  margin: 3.2rem 0 1.8rem 0;
  width: 100%;
  color: #999;
  padding-left: 1.6rem;
}
.itemsPf:hover,
.itemsPf:active {
  transition: var(--speed) ease;
  background-color: #f3f3f3;
  border-radius: 0.4rem;
  color: #2d5ecf;
}
.profileAsideSec .itemsPf:hover,
.profileAsideSec .itemsPf:active {
  transition: var(--speed) ease;
  background-color: transparent;
  color: #f35e6a;
} 

.logout_profile {
  padding: 0.7rem 1.8rem;
  background-color: transparent;
  color: #666;
}

.logout_profile:hover, .logout_profile:active {
  color: #f35e6a;
}
.close-prl-tab {
  transition: var(--speed) ease;
}

.profileContents {
  padding: 2.4rem;
}

.dp {
  height: 2.4rem;
  width: 2.4rem;
  background-color: #6693fa;
  color: #fff;
  border-radius: 55rem;
  overflow: hidden;
  justify-content: center;
  font-weight: 700;
}

.profileHeader a:link,
.profileHeader a:visited {
  color: #999;
  font-size: 1.4rem;
}
.notifBtn {
  position: relative;
  color: #999;
  cursor: pointer;
}
.notifBtn:active, .notifBtn:hover { 
  color: #2d5ecf; 
}
.notifBtn figure {
  width: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  background-color: #f35e6a;
  color: #fff;
  border-radius: 12rem;
  position: absolute;
  padding: 0.2rem;
  right: -30%;
  top: -39%;
}
 

/* Activity log decoration block */ 
div.activityLogCont thead {
  background-color: #2961c4;
  color: #fff;
  border-radius: 0.4rem;
  padding: 1.2rem 2rem;
  margin-top: 2.4rem;
}

div.activityLogCont thead,
div.activityLogCont tbody tr {
  display: grid;
  gap: 2.4rem;
  grid-template-columns: 0.4fr 1fr 2.5fr 1fr 1fr 1fr;
  text-align: left;
  font-size: 1.5rem;
}

div.activityLogCont tbody tr {
  font-size: 1.4rem;
  padding: 0.4rem 2rem;
  /* border-bottom: 1px solid #eee; */
}

div.activityLogCont tbody tr:nth-child(even) {
  background-color: #fafaff;
  border: 1px solid #eee;
}
.actLogIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.4rem;
  height: 3.4rem;
  color: green;
  padding: 0.3rem;
  border-radius: 44rem;
  background-color: #fafaff;
  border: 1px solid #eee;
}
.actLogDate {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

/* Force Comp Decoration blocks */
.fIcon {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 333rem;
  /* background-color: #2961c4; */
  background-image: linear-gradient(rgb(255, 192, 203), rgb(255, 215, 0));
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forceCards {
  padding: 3.4rem;
  background-color: #fff;
  width: 50%;
  border-radius: 1.2rem;
  gap: 2.4rem;
}

.forceCards figure {
  border-radius: 1.2rem;
  border: 1px solid #f3f3f3;
  background-color: #fafaff;
  padding: 1.4rem;
  gap: 1.2rem;
  color: #666;
  transition: all 0.3s;
  cursor: pointer;
}

.forceCards figure:hover,
.forceCards figure:active {
  background-image: linear-gradient(to right, rgb(7, 6, 82), rgb(69, 125, 247));
  color: #fff;
}

.forceImageSlide { 
  width: 99rem;
  background-color: #fff;
  border-radius: 1.2rem;
  /* border: 1px solid #ccc; */
  padding: 3.4rem; 
}



.payCard h3 {
  font-size: 2.8rem;
  font-weight: 600;
}
.payCard figure {
  width: 25rem;
  height: 39rem;
  padding: 2.4rem;
  background-color: rgb(242, 242, 242);
  /* height: 44rem; */
  font-size: 1.4rem;
  text-align: center;
}

.btnPay button {
  border-radius: .4rem;
  background-color: #2d5ecf;
  font-size: 1.4rem;
  padding: .8rem 2.1rem;
  color: #fff;
  transition: all .3s;
}

.btnPay button:hover,
.btnPay button:active{
  background-color: #153162;
}


/* dashBord dec block */

.dashBHead figure {
  width: 25%;
  padding: .8rem 3.2rem;
  background-color: #fff;
  border-left: 1px solid #f3f3f3;
  color: #999;
  font-size: 1.2rem;
}
.dashBHead figure:last-child { 
  border-right: 1px solid #f3f3f3; 
}

.dashBHead figure h2{
  color: #333;
  font-size: 2.6rem;
  font-weight: 300;
}

.dashBHead figure aside {
  width: 20%; 
  height: 4.5rem;
  overflow: hidden;
}
.dashBHead figure aside img { 
  height: 3.7rem; 
  border-radius: .4rem;
}

.statusBar{
  color: #666;
  
}
.userDbData {
  gap: 1.5rem;
}
.userDbData li {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: .5rem; 
}
.statusBar, .bannerClient{
  width: 50%;
  padding: 4.5rem 3.2rem; 

}
.bannerClient{
  color: #fff;
  background-color: #3847d0;
  border-radius: .5rem;

}
.bannerIcon {
  width: 40%;
  padding-right: 2rem;
  border-right: 1px solid #999;
}
.bannerDisc {
  width: 60%;
  font-size: 1.3rem;
  /* border-left: 1px solid #999; */
  font-weight: 100;
  padding-left: 2.3rem;
}

.bannerClient img {
  transform: scale(2.5);
}

.bnBtn:link, .bnBtn:visited {
  width: 9.5rem;
  background-color: #fff;
  color: #3847d0;
  padding: .6rem 1.4rem;
  border-radius: .4rem;
  text-align: center;
  font-weight: 700;
  font-size: 1.2rem;
}

.lg {
  width: 80%;
  padding: .8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lg img {
  border-radius: 1.2rem;
  height: 15rem;
}

.statusBar  {
  justify-content: space-between;
  padding: 2.4rem;
  height: 20rem;
}

.highScoreChart {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.highScoreChart figure.last {
  left: 100%;
  background-color: red;
  

}
.highScoreChart figure {
  background-color: #00d285;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -110%);
  border-radius: 444rem;
  padding: .4rem;
  height: 4rem;
  width: 4rem;
}

.dashBCard {
  padding: 1.2rem 2.6rem;  
  gap: 1.2rem;
}

.sts {
  border-top: 1px solid #ddd;
}
.dashBCard.cn {
 border-right: 1px solid #ddd;
 border-left: 1px solid #ddd;
 border-bottom: 1px solid #ddd;

}
.dashBCard.a {
 border-top: 1px solid #ddd; 
}
.dashBCard.c {
 border-top: 1px solid #ddd; 
}
.dashBCard h6 {
  font-size: 2.4rem;
}
.dashBCard figure {
  width: 5.4rem;
  height: 5.4rem;
}
.dashBCard aside {
  width: .8rem;
  height: .8rem;
  border-radius: 9999rem;
  background-color: rebeccapurple;
}


/* Account Details decoraiton blocks */

.accountleft
.imgCont {
  background-color: #f3f3f3;
  padding: 3.4rem;
  padding-top: 4.5rem;
}

.accountImage {
  border-radius: 9999rem;
  height: 15rem;
  width: 15rem;
  background-color: #6693fa;
  color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8.5rem;
  font-weight: 600;
  }

  .accountleft button {
    background-color: transparent;
    border-radius: .3rem;
    border: 1px solid #ddd;
    color: #999;
    padding: .8rem .9rem;
  }
  .accountleft button:hover { 
    background-color: #00d285;;
    color: #fff; 
  }
  
  .acctLink:link, .acctLink:visited {
    background-color: #d6ecfc;
    padding: .5rem 1.2rem;
    border-radius: .4rem;
    color: #2d5ecf;
    font-weight: 500;
    font-size: 1.2rem;
  }

  .asDisc {
      color: #999;
  }


  /* material decoration block */

  .materialSubj button {
    width: 14rem;
    padding: .8rem 1.2rem;
    border-radius: .5rem;
    background-color: #6c8edd; 
    color: #fff;
    font-size: 1.3rem;
    font-weight: 700;
  }
  .materialTable table {
    width: 100%;
   
  }
  /* .materialTable table tbody {
    border: 1px solid #ccc;
   
  } */
  .materialTable tr {
    display: grid;
    grid-template-columns: 1fr 4fr 2.5fr;
    padding: .9rem;
    border-radius: .8rem;
  }
  .materialTable table thead tr {
    background-color: #1ad791;
    color: #003f28;
  }
  .affiliationTable table {
  width: 100%;
  }
  .affiliationTable tr {
    display: grid;
    grid-template-columns: 1fr 4fr 2.5fr 2.5fr 2.5fr 2.5fr 2.5fr;
    padding: .9rem;
    border-radius: .8rem;
  }
  .affiliationTable table thead tr {
    background-color: #1ad791;
    color: #003f28;
  }
  .materialTable table tbody tr:nth-child(even) {
    background-color: #003f284f;
    color: #003f28;
  }
  /* .materialTable table tbody tr:nth-child(odd) {
    background-color: #fafaff;
    color: #666;
  } */

.sectionInstructorProfile {
  position: relative;
}
.profileAsideResponsive{ 
  background-color: #000;
  position: absolute;
  padding: 2.4rem;
  top: 0;
  left: 0;
  z-index: 9999;
}
.toggleProfileNav { 
display: none;
position: absolute;
top: 0;
left: 0;
padding: 1.3rem;
z-index: 9999999;
}
.toggleProfileNav button{ 
  color: #fff;
}
  /* _____________________ RESPONSIVE DESIGN ______________________ */

/* ___________________________________________________ MEDIA QUERIES BLOCKA ____________________________________ */

/* rem and em do NOT depend on html font-size in media queries! Instead, 1rem = 1em = 16px */

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
  
}


/**************************/
/* BELOW 1213px (Landscape Tablets) */
/**************************/

@media (max-width: 75.8125em) {
  .forceImageSlide {
    width: 93rem; 
}
.payCard figure {
  width: 23rem;
  height: 35rem;
  padding: 1.4rem;
  gap: 2.2rem;
}

 
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media only screen and (max-width: 75em) {
  /* .sectionMDProfile .dashBHead figure h2 {
    font-size: 1.8rem !important;
} */

.dashBHead figure h2 { 
  font-size: 2rem; 
}

.bannerIcon {
  width: 33%;
  padding-right: 1.6rem; 
}
.bannerClient img {
  transform: scale(1.7);
}

.statusBar, .bannerClient { 
  padding: 2.1rem;
}
.dashBCard h6 {
  font-size: 2rem;
}
.lg img { 
  height: 12rem;
}


/* .payCard figure {
  width: 21rem;
  height: 32rem;  */
/* }
.swiper-backface-hidden .swiper-slide { 
  margin-right: 0 !important;
} */
.payCard p{
  font-size: 1.2rem;
}
  
}
 
/**************************/
/* BELOW 1115px (Tablets) */
/**************************/
@media (max-width: 69.68em) {
  .forceImageSlide {
    width: 89rem;
  }
}
/**************************/
/* BELOW 1070px (Tablets) */
/**************************/
@media (max-width: 66.875em) {
  .forceImageSlide {
    width: 81rem;
  }
}
/**************************/
/* BELOW 1017px (Tablets) */
/**************************/
@media (max-width: 63.5625em) {
  .forceImageSlide {
    width: 75rem;
  }

  .dp {
    margin-right: 2.3rem;
}
}
/**************************/
/* BELOW 1150 (Tablets) */
/**************************/

@media (max-width: 71.9em) {
  .statusBar {
    width: 40%; 
}
.bannerClient {
  width: 60%;
}
.dashBHead figure { 
  padding: .8rem  1.8rem; 
}

.dashBHead figure aside img {
  height: 3.7rem; 
  width: 3.7rem;
}

.dashBHead figure aside {
  width: 32%; 
}
.dashBCard h6 {
  font-size: 1.7rem;
}
}
/**************************/
/* BELOW 945px (Tablets) */
/**************************/

@media (max-width: 59.06em) {
  .profileAside {
    display: none;
}
.instructorProfileCont { 
  display: flex; 
  flex-direction: column;
}
.toggleProfileNav {
  display: block;
}
.toggleProfileNav button {
  color: #fff;
  background-color: transparent;
  border: none;
}

.profileAsideResponsive a:link, .profileAsideResponsive a:visited {
  color: #fff;
}

 .profileHeader  span:first-child{
  margin-left: 1.4rem;
 }

 .profileHeader { 
  padding-top: 3.8rem;
} 
.logout_profile {
  padding: 0.7rem 1.8rem;
  background-color: transparent;
  color: #fff;
}

.statusContainer {
  flex-direction: column;
  gap: 2.4rem;
  }
  .statusBar { 
    background-color: #fafaff;
    padding: 2.4rem;
    border-radius: 1.2rem;
}

.statusBar, .bannerClient {
  width: 90%;
}

.profileCardsConts {
  display: flex;
  flex-direction: column;
  gap: 2.4rem; 
}

.forceImageSlide {margin: 0 auto;}

}


/**************************/
/* BELOW 775px (Tablets) */
/**************************/

@media (max-width: 48.4375em) {
  .payCard figure {
    width: 21rem;
    height: 32rem; 
    gap: 1.8rem;
}

.forceImageSlide {
  width: 72rem;
}

}

/**************************/
/* BELOW 605px (Tablets) */
/**************************/

@media (max-width: 37.8125em) {
 
  .lg img {
    height: 8rem;
}
.payCard figure {
  width: 18rem;
  height: 29rem;
  gap: 1.8rem;
}

.btnPay button { 
  font-size: 1.1rem;
  padding: 0.5rem 1.6rem; 
}

.forceImageSlide {
  width: 68rem;
}
}


/**************************/
/* BELOW 560px (Tablets) */
/**************************/

@media (max-width: 35em) {
  .forceImageSlide {
    width: 61rem;
}
}
/**************************/
/* BELOW 800px (Tablets) */
/**************************/


@media (max-width: 50em) {
 
 
}


/**************************/
/* BELOW 740px (Tablets) */
/**************************/
@media (max-width: 46.25em) {
 
}


/**************************/
/* BELOW 544px (Phones) */
/**************************/
@media (max-width: 34em) {
 
}
@media (max-width: 26.375em) {
 
  
}
@media (max-width: 23.375em) {
  
}
@media (max-width: 18.6875em) {
 
}