.sectionHomePage {
  font-size: 1.4rem;
}

.homebody {
  display: flex;
  flex-direction: column;
  gap: 9.5rem;
}

/* Header Decoration blocks */

.notification_bar {
  gap: 2.3rem;
  padding: 1.2rem;

  /* background-image:  linear-gradient( rgba(255, 179, 0, 0.363), 
    rgba(255, 123, 0, 0.308) );  */
  background-color: rgb(240, 240, 187);
  font-size: 2rem;
  font-weight: 600;
}

.notification_bar button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.navigation_bar {
  position: relative;
  gap: 1.2rem;
  padding: 1.2rem;
  margin: 0;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: transparent;
  color: #fff;
}

.navigation_bar ul,
.navigation_bar aside {
  gap: 1.9rem;
  list-style: none;
  text-decoration: none;
  padding: 0;
}

.navigation_bar ul li {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  transition: all 0.3s;
}

.navigation_bar a:link,
.navigation_bar a:visited {
  color: #fff;
}
.navigation_bar a:hover,
.navigation_bar a:active {
  color: #f7ca86;
}

.navBtn {
  border: 1px solid #fff;
  border-radius: 0.4rem;
  padding: 0.1rem 1.5rem;
}
.navBtn:hover,
.navBtn:active {
  border: 1px solid #f7ca86;
}

.navBarTag {
  background-color: #fff;
  border-radius: 0.2rem;
  padding: 0rem 0.5rem;
  color: #333;
  font-size: 0.9rem;
}
.headerContainer .toggleNav  {
  display: none; 
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}

.toggleNav button{
  background-color: transparent;
  border: none;
  color: #fff;
}
.mobileNav {
  position: absolute;
  left: 0; 
  top: 0; 
  background-color: rgba(0, 0, 0, 0.801);
  padding: 3.4rem !important;
  height: 100vh;
  visibility: hidden;
}

.mobileNav a:link,.mobileNav a:visited {
  color: #fff;
}
/* HeroSection Decoration blocks */
.section_hero {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  background-color: rgba(45, 94, 207, 0.575); 
  /* background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/army.jpg);
  background-repeat: no-repeat;
  background-size: cover; */
  background-color: transparent;
  position: relative;
}

.heroBgSlider {
  z-index: -99999;
  width: 100%;
  height: 100%;
  background-color: rgb(167, 154, 154);
  position: absolute;
  top: 0;
  right: 0;
  
/* background-color: rgb(45, 94, 207);  */
  background-repeat: no-repeat;
  background-size: cover;
}

.heroBgSlider.a{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/a.jpg);
}


.heroBgSlider.b{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/b.webp);
}
.heroBgSlider.c{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/c.jpg);
}
.heroBgSlider.cc{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/cc.jpg);
}
.heroBgSlider.d{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/d.jpg);
}
.heroBgSlider.e{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/e.jpg);
}
.heroBgSlider.f{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/f.jpg);
}
.heroBgSlider.g{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/g.webp);
}
.heroBgSlider.h{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/h.jpg);
}
.heroBgSlider.i{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/i.jpg);
}
.heroBgSlider.j{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/j.jpg); 
}
.heroBgSlider.k{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/k.jpg);
}
.heroBgSlider.l{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/l.jpg);
}
.heroBgSlider.m{
  background-image: linear-gradient(
      to right bottom,
      rgba(8, 12, 34, 0.712),
      rgba(164, 164, 235, 0.5)),
    url(../homepage//header/headerImage/m.jpg);
}





.hero_container {
  height: 58rem;
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 1.2rem 2.4rem;
}

.hero_container button {
  border: none;
}

.siteHeader {
  font-size: 6.3rem;
  margin: 0;
}
.siteStatus {
  font-size: 2.8rem;
  margin: 0;
}
.hero_discription {
  margin: 0 auto;
  width: 70%;
  font-size: 2rem;
  text-align: center;
  line-height: 1.5;
}
.search_container {
  display: flex;
  width: 70%;
  gap: 1.2rem;
  margin: 0;
}
.search_bar {
  display: flex;
  gap: 0.6rem;
  width: 75%;
  font-size: 1.6rem;
  margin: 0;
  background-color: #fff;
  border-radius: 0.4rem;
  overflow: hidden;
  padding: 0.8rem 0.5rem;
}
.search_terms,
input[name="searchBar"] {
  font-size: 1.6rem;
  padding: 0.5rem;
  border: none;
  height: 80%;
}
input[name="searchBar"] {
  border-left: 1px solid #bbb;
}
.search_terms {
  width: 25%;
}

input[name="searchBar"] {
  width: 75%;
}
.tutorialBtn {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  width: 25%;
  background-color: #fff;
  border-radius: 0.4rem;
  padding: 1.3rem;
  color: #333;
  font-size: 1.6rem;
  margin: 0;
  font-size: 1.4rem;
  font-weight: 600;
}

.hero_tag {
  display: flex;
  gap: 1.2rem;
  width: 50%;
  font-size: 1.2rem;
  font-weight: 700;
}

.hero_tag span {
  background-color: #ffffff3d;
  color: #fff;
  border-radius: 0.4rem;
  padding: 0.3rem 1.2rem;
}
.hero_card_container {
  display: none;
  gap: 1.2rem;
  width: 60%; 
  font-size: 1.8rem;
  margin: 2.4rem 0; 
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translate(50%, 50%);
  margin: 0;
  padding: 0; 
  border-radius: 2.2rem;
  overflow: hidden;
  border-radius: 4.8rem;
  box-shadow: 1rem 1rem 2rem 0 rgba(0, 0, 0, 0.35);
}

.hero_card {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* border-radius: 0.8rem; */
  margin: 0;
  overflow: hidden;
  padding: 0.2rem;
  /* border-radius: 4.8rem;
  box-shadow: 1rem 1rem 2rem 0 rgba(0, 0, 0, 0.35); */
}

.hero_card img {
  width: 100%;
  scale: 1.02;
  /* border-radius: 4.8rem;
  box-shadow: 1rem 1rem 2rem 0 rgba(0, 0, 0, 0.35); */
}

.hero_card span {
  font-size: 1.5rem;
  font-weight: 600;
} 
/* ----------------------------------
 FOOTER Decoration Blocks
-------------------------------------- */
footer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #1c1d1f;
  /* background-color: #121212; */
  /* background-color: #12334c; */
  color: #fff;
  padding: 3.4rem;
}

.footerStart {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.headingFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headingFooter h2 {
  font-size: 2.8rem;
  text-transform: uppercase;
}

button[type="lang"] {
  background-color: transparent;
  border: 1px solid #fff;
  display: flex;
  gap: 0.3rem;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 0.2rem 3rem 0.2rem 1.2rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.5s;
}

button[type="lang"]:hover,
button[type="lang"]:active {
  background-color: #fff;
  color: #29475e;
}
.footerItems {
  display: flex;
  gap: 4.2rem;
}
.footerItems ul {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  list-style-type: none;
  font-size: 1.6rem;
}

.footerItems a:link,
.footerItems a:visited {
  color: #fff;
  transition: all 0.3s;
}

.footerItems a:hover,
.footerItems a:active {
  box-shadow: inset 0 -2px 0 0 #fff;
}

.footerMiddle {
  display: flex;
  align-items: center;
  gap: 2.4rem;
  justify-content: space-between;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 1rem 0rem;
}

.subscribe {
  font-size: 1.8rem;
  font-weight: 600;
  opacity: 1;
}

form[name="footerForm"] {
  border: 0.5px solid #d8e5f8;
}

input[type="footerInput"] {
  background-color: #d8e5f8;
  color: #333;
  font-size: 1.6rem;
  padding: 0.8rem;
  border: none;
}

button[type="footerSubmit"] {
  background-color: transparent;
  border: none;
  color: #fff;
  padding: 0.8rem;
  cursor: pointer;
  height: 100%;
}

button[type="footerSubmit"]:hover,
button[type="footerSubmit"]:active {
  opacity: 0.6;
}

.footerMediaIcons a {
  color: #ffffff;
}
.footerMediaIcons {
  gap: 2.4rem;
}

.footerMediaIcons Link:link,
.footerMediaIcons Link:visited {
  transition: all 0.3s;
}

.footerMediaIcons a:hover,
.footerMediaIcons a:active {
  transform: scale(1.2);
}

.footerMediaIcons.flex a:link,
.footerMediaIcons.flex a:visited {
  color: #3e71ca;
  transition: all 0.3s;
}
footer .footerMediaIcons.flex a:link,
footer .footerMediaIcons.flex a:visited {
  color: #fff;
}
.copyRight {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
}

.copyright {
  font-size: 1.5rem;
}

ul.listEnd {
  display: flex;
  gap: 4rem;
  font-size: 1.6rem;
  font-weight: 300;
  list-style: none;
}

.listEnd a:link,
.listEnd a:visited {
  text-decoration: none;
  border-left: 2px solid #fff;
  padding-left: 1rem;
  color: inherit;
  transition: all 0.3s;
}

.listEnd a:hover,
.listEnd a:active {
  color: rgb(255, 196, 0);
}


/* Home Z partterns decoration block */

.homeInfoCont {
  width: 90%;
  column-gap: 9rem;
  row-gap: 9rem;
  align-items: center;
}

 

.homeLink.b:link, .homeLink.b:visited { 
    width: 29rem;
    background-color: rgba(0,0,102, 0.205);
    box-shadow: inset 0 0 0 1px #b3b3d9;
    color: #fff; 
}
.homeLink:link, .homeLink:visited {
  background-color: #2d5ecf;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1.2rem 1.8rem;
  border-radius: 0.6rem;
  text-align: center;
  width: 40%;

}

.homeLink.b:hover, .homeLink.b:active, .homeLink:hover, .homeLink:active {
  background-color: #cccce6;
  color: #2d5ecf;
}
.infoPag {
  margin-top: 1rem;
  margin-bottom: 3.4rem;
  line-height: 1.5;
}

/* section Home header decoration block */

.hHcont {
  background-color: #f1f1f1;
  padding: 2.4rem 4.5rem;
}

.hHcont h2 {
  font-size: 2.8rem;
  font-weight: 500;
  text-align: center;
}
.hHcont p {
  text-align: center;
}

.hHLogo span {
  width: 8rem;
  height: 8rem;
}
.hHLogo span img {
  opacity: .7;
  width: 100%;
  height: 100%;
  
}


/* Section Call to Action decoration Blocks */
.ctaCont  {
  display: grid;
  grid-template-columns: 2fr 1.2fr;
  width: 90%;
  margin: 0 auto;
  border-radius: .8rem;
  overflow: hidden;
  box-shadow: 0.8rem 0.8rem 2rem 0 rgba(0, 0, 0, 0.35);
}

.ctaOne  {
  display: grid;
  grid-template-columns: 2fr 1.2fr;
  width: 90%;
  margin: 0 auto;
  border-radius: .8rem;
  overflow: hidden;
  box-shadow: 0.8rem 0.8rem 2rem 0 rgba(0, 0, 0, 0.35);
}
.ctaDisc {
  /* background-color: #13544e; */
  background-image: linear-gradient(to right bottom, rgb(0, 0, 77), rgb(0, 0, 38));
  color: #fff;
  padding: 4.2rem; 
  font-size: 1.8rem;
}
.sectionCTA2 .ctaDisc { 
  background-image: linear-gradient(to right bottom, #04aa6d, #19a5a5);
  color: #fff;
  padding: 4.2rem; 
  font-size: 1.8rem;
}

.sectionCTA2 .ctaDisc p{
  line-height: 1.3;
}

.ctaSubHead {
  font-size: 2.4rem;
  font-weight: 600;
  
}

.ctaDisc h3{
  font-size: 4.5rem;
  font-family: serif;
  line-height: 1;
  font-weight: 300;
}
.ctaDisc h3{
  font-size: 4.5rem;
  font-family: serif;
  line-height: 1.2;
  font-weight: 300;
}


.ctaBtn:link,.ctaBtn:visited{
  background-color: #b3b3d9;
  color: #00004d;
  border-radius: 999rem;
  padding: 1.2rem 2.2rem;
  font-size: 1.4rem;
  font-weight: 600;
  
 }
 
.sectionCTA2 .ctaBtn:link,.sectionCTA2 .ctaBtn:visited{
  background-color: #fff;
  color: #04aa6d; 
  
 }
 
.sectionCTA2 .ctaBtn:hover,.sectionCTA2 .ctaBtn:active{
  background-color: #043eaa;
  color: #fff; 
  
 }
 
 .ctaBtn:hover,.ctaBtn:active{
  background-color: #fff;
}

.ctaImg {
  background-image: linear-gradient(rgba(189, 5, 5, 0.253), rgba(5, 5, 66, 0.212)), url(./publicImages/fsoja.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.sectionCTA2 .ctaImg {
  background-image: linear-gradient(rgba(189, 5, 5, 0.253), rgba(5, 5, 66, 0.212)), url(./publicImages/jambs.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.sectionFeature {
  padding: 3.4rem 6.4rem;
  font-size: 1.6rem; 
}

.featuredCont {
  margin: 0 auto; 
}

.featuredCont h4 {
  font-weight: 700;
}

.featuredCont p {
  line-height: 1.5;
}






/* ___________________________________________________ MEDIA QUERIES BLOCKA ____________________________________ */

/* rem and em do NOT depend on html font-size in media queries! Instead, 1rem = 1em = 16px */

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
  
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media only screen and (max-width: 75em) {
  .ctaCont{
    grid-template-columns: 2fr 1.5fr;
  }
  .footerItems ul {
    font-size: 1.4rem;
  }
  .homeInfoCont.g {
    gap: 4.4rem;
    row-gap: 6.8rem;
  }

  .infoPag {
    font-size: 1.5rem;
  }

  .ctaDisc h3 {
    font-size: 3.6rem;
  }
  
  .ctaDisc {
    font-size: 1.5rem;
  }

  .ctaSubHead {
    font-size: 1.4rem;
  }
  
}


/**************************/
/* BELOW 999px (Tablets) */
/**************************/

@media (max-width: 62.4em) {
  .hero_card_container {
    display: none;
  }

  .hero_container {
    height: 50rem;
  }

  .sectoinHomeheader  div:first-child {
    display: none;
  }
  .ctaCont {
    grid-template-columns: 2fr 2fr;
  }
  .sectionCTA2 .ctaDisc  {
    gap: 1rem;
  }
  .ctaDisc h3 {
    font-size: 3rem;
  }

  .featuredCont p {
    font-size: 1.4rem;
  }
  .sectionCTA .ctaOne {
    font-size: 1.1rem;
    grid-template-columns: 2fr 1fr !important;
  }
  .sectionCTA .ctaDisc  {
    gap: .6rem; 
  }

  .ctaList {
    gap: .8rem;
  }

  .siteHeader {
    font-size: 5.6rem;
  }

  .hHcont h2 {
    font-size: 2.5rem;
    font-weight: bold;
  }
  .hHLogo {
    width: 70%;
    gap: 2.2rem;
    justify-content: center;
  }

  .hHLogo span img {
    height: 6rem;
    width: 6rem;
  }
  .subscribe {
    font-size: 1.3rem;
  }
  .footerForm inpt, .footerForm button {
    font-size: 1.3rem;
  }

  ul.listEnd {
    font-size: 1.2rem;
  }
  
 
.copyRight { 
    font-size: 1.2rem;
}
.listEnd li:nth-child(3) {
  display: none;

}

}


/**************************/
/* BELOW 860px (Tablets) */
/**************************/

@media (max-width: 54em) {
  .siteHeader {
    font-size: 4.8rem;
  }
  
  .hHcont h2 {
    font-size: 2rem;
  }
  .sectionCTA2 .ctaDisc {
    font-size: 1.4rem;
  }

  .homeInfoCont h2  {
    font-size: 2.8rem;
  }
 
  .sectionFeature {
    padding: 3.4rem ;
    font-size: 1.6rem;
}

.featuredCont .g {
  gap: 2rem;
}

.featuredCont h4 {
 font-size: 1.2rem;
 font-weight: bolder;
}

.hero_container  {
  gap: 2.8rem;
}
.hero_discription {
  width: 90%;
}
.sectionCTA .ctaDisc {
 font-size: 1.2rem;
 padding: 1.6rem;
}

.footerMiddle form {
  display: none;
}

}

/**************************/
/* BELOW 800px (Tablets) */
/**************************/


@media (max-width: 50em) {
  ul.listEnd {
    display: none;
    color: red;
  }
  .copyRight {
    align-items: center;
    justify-content: center;
  }
  .navigation_bar ul li, .navigation_bar ul, .navigation_bar aside{
    font-size: 1.3rem;
  }
}


/**************************/
/* BELOW 740px (Tablets) */
/**************************/
@media (max-width: 46.25em) {
  html{
    font-size: 50%;
  }
  .sectionCTA .ctaDisc { 
    padding: 3.6rem;
}
  .homeInfoCont  {
  column-gap: 0rem;
  
 }
 

 .featuredCont div:first-child{
  grid-template-columns: 1fr 1fr;
 }
  
  .siteHeader {
    font-size: 4.2rem;
    margin: 0;
    text-align: center;
}

.navigation_bar {
 display: none;
}

.hero_container {
 padding: 2.8rem;
 justify-content: center;
 
}

.infoPag {
  font-size: 1.8rem;
}
 
.hero_container {
    height: 40rem;
}

.featuredCont p {
  font-size: 1.64rem;
}

.featuredCont h4 {
  font-size: 1.6rem; 
}

.homeInfoCont h2 {
  font-size: 2.4rem;
}
 
.hHcont { 
  padding: 2.4rem 2.5rem;
}
}


/**************************/
/* BELOW 544px (Phones) */
/**************************/
@media (max-width: 34em) {
  .mobileNav{
    display: block !important;
    visibility: visible ;
  }
  .headerContainer .toggleNav  {
    display: block; 
  }
  .section_hero{
    height: 90vh;
    padding: 2.4rem;
  }

  .siteHeader {
    font-size: 4.8rem;
}
  .navigation_bar   {
    display: none;
  }
  .sectionCTA2 .ctaCont {
    display: flex;
    flex-direction: column;
}
 

.infoPag {
  font-size: 2.2rem;
}

.homeLink:link,
.homeLink:visited { 
  font-size: 1.8rem;
  font-weight: 300;
  padding: 1.2rem 1.8rem; 
  width: 43.1%;
}


.homeInfoCont h2 {
  font-size: 4.4rem;
}
.hero_container {
  height: 80vh;
  gap: 4rem;
}

.hero_discription { 
  font-size: 3rem; 
  line-height: 1.5;
  font-weight: 200;
}

 
 
.siteHeader {
  font-size: 5.8rem; 
}


.homeInfoCont.g {
  grid-template-columns: 1fr;
}

.sectionCTA .ctaOne {
  font-size: 1.1rem;
  grid-template-columns: 2fr !important; 

} 


.sectionCTA .ctaOne .ctaDisc  {
  padding: 4.5rem;
  gap: 3.12rem;
} 
.sectionCTA .ctaOne p {
  font-size: 2.4rem; 
} 
.sectionCTA .ctaOne span {
  font-size: 1.8rem; 
} 

.ctaDisc h3 {
  font-size: 4.8rem;
}


.homeInfoCont  div:first-child {
  grid-row: 2/3;
}
.homeInfoCont  div:last-child {
  grid-row: 5/6;
}

.sectionCTA .ctaOne .ctaImg {
  grid-row: 1/2;
}
.secPattern {
  width: 90%;
  border-radius: 1.2rem;
  margin: 0 auto;
}
.ctaSubHead {
  font-size: 2.7rem;
}
.sectionCTA2 .ctaDisc {
  padding: 5.5rem;
  gap: 1.2rem;
  padding-bottom: 8rem;
  font-size: 2.9rem;
}

.sectionCTA2 .ctaBtn:link,
.sectionCTA2 .ctaBtn:visited {
  font-size: 2.3rem;
  padding: 1.8rem 3.4rem;
}

.sectionCTA .ctaOne span.ctaHeading {
  font-size: 4.8rem;
}

.ctaBtn:link, .ctaBtn:visited {
  width: 50%;
  border-radius: 999rem;
  padding: 1.2rem 2.2rem;
  font-size: 2.4rem;
  font-weight: 300; 
}
.sectionCTA .ctaOne p {
  margin-bottom: 1rem;
}

.sectionCTA .ctaOne .ctaDisc {
  gap: 1.2rem;
}

.ctaList { 
  margin-bottom: 4rem;
}
.homeInfoCont.ptrn.g{
  row-gap: 1rem;
  text-align: center;
  justify-content: center;
}
.homeInfoCont.ptrn.g h2{
font-size: 2.8rem;
line-height: 1.8;
}

.homeInfoCont.ptrn.g .homeLink:link, .homeInfoCont.ptrn.g .homeLink:visited {
  align-self: center;

}
}
 
@media (max-width: 26.375em) {
  html{
    font-size: 40%;
  }
  .featuredCont p {
    font-size: 1.94rem;
}
  
}
@media (max-width: 23.375em) {
  html{
    font-size: 35%;
  }
  .featuredCont p {
    font-size: 1.94rem;
}
  
}
@media (max-width: 18.6875em) {
  html{
    font-size: 30%;
  } 
  
}