.sectionRegistratoin {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.4rem;
    flex-direction: column; 
    background-image: url(./publicImages/regBg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
.regCont {
    width: 75%;
    height: 55rem;
    gap: 0;
    grid-template-columns: 1fr 1fr;
    background-color: #fff;
    box-shadow: 0rem 4rem 2rem -3rem rgba(0, 0, 0, 0.35);
    border-radius: 1.2rem;
    overflow: hidden;
} 

.regH3 {
    font-size: 4rem;
    font-family: serif;
    text-align: center;
}

.regFormCont { 
    height: 100%; 
} 

.regFormCont h3 {
    color: #000026;
    align-self: center;
    font-size: 2.4rem;
    font-weight: 500;
}
.regFormCont button {
    width: 28rem;
}

.regstateOfRes {
    width: 100%;
    background-color: transparent;
    padding: .5rem;
    color: #666;
}
.regDisc  {
    width: 100%;
    height: 100%;
    padding: 1.4rem;
    align-self: center; 
    justify-self: center;
    background-color: #1a1a8d;
    /* background-image: linear-gradient(to right bottom, rgba(0, 0, 90, 0.525), rgb(0, 0, 128));  */
    color: #fff;
}

.sectionRegistratoin .logo {
    position: absolute;
    top: 1.7rem;
    left: 1.7rem;
    width: 8%;
}

.logo img {
    width: 100%;
}
 

 
.registerformCont {
    background-color: #fff; 
    padding: 4.5rem;
    position: relative;
}

.regFormSub {
    font-size: 1.2rem;
    padding: 1.4rem;
    color: #1a1a8d;
    position: absolute;
    right: 0;
    top: 0; 
}
.sectionRegistratoin .loginformCont {
    height: 100vh;
    background-color: #fff;
    margin: 0;
    grid-template-columns: 1fr 1.6fr;
    gap: 0;
}
.RegForm {
    /* margin:  0 auto; */
    width: 100%;
}

.RegForm div {
    /* background-color:#fafaff;;  */
    border-radius: .4rem;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .4rem .8rem;
    border: 1px solid #ddd
}

.formTitle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.4rem;
}

.formTitle select {
    padding: .6rem .4rem;
    background-color: transparent;
    color: #666;
    width: 100%;
}
.RegForm input {
    background-color: transparent;
    padding: .8rem;
    font-size: 1.3rem;
    width: 100%;

    
}




/**************************/
/* BELOW 950px (tablet landscape) */
/**************************/ 
@media (max-width: 60em) {
    .regCont {
        width: 95%; 
    }
}
 
/**************************/
/* BELOW 650px (tablet ) */
/**************************/ 
@media (max-width: 41em) {
    .registerformCont { 
        padding: 2.5rem; 
    }

    .regCont  {
        grid-template-columns: 2fr 3fr;
    }
}
 
/**************************/
/* BELOW 544px (Phones) */
/**************************/
@media (max-width: 34em) {
    .registerformCont { 
        padding: 4.5rem; 
    }

    .regCont  {
        grid-template-columns:1fr;
    }

    .regDisc  {
        display: none;
    }

    .RegForm div { 
        padding: 0.4rem 0rem; 
    }
}
 
/**************************/
/* BELOW 298px (Phones) */
/**************************/
@media (max-width: 18.64em) {
    .regCont {
        width: 100%;
    }

    .registerformCont {
        padding: 4.5rem 2rem;
    }

}
  
/**************************/
/* BELOW 260px (Phones) */
/**************************/
@media (max-width: 16.25em) {
    .formTitle { 
        grid-template-columns:  1fr; 
    }

}
  