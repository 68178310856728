.sectionLogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fafaff;  
  background-image:  url(../logingBg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 1.4rem;
}

.loginDiscription {
  width: 45%;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2.4rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: #fff;
  background-image: linear-gradient(
      to right bottom,
      rgb(45, 93, 205),
      rgb(183, 94, 242)
    );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.loginDiscription.arm {
  height: 100%;
  background-color: #f3f3f3;
  
}
.loginDiscription figure {
  width: 40%;
}
.loginDiscription h2 {
  font-family: serif;
  font-size: 3.4rem;
  font-weight: 100;
}

.loginCont {
  z-index: 9999;
  width: 70%;
  border-radius: 1.2rem; 
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0.4rem 0.6rem 2rem 0rem rgba(0, 0, 0, 0.35);
  /* background-color: red; */
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-self: flex-start;
  justify-self: center;
  padding: 4.4rem 8rem;
  background-color: #fff;
  color: #999;
}

.loginForm h4 {
  color: #333;
  font-size: 3.6rem;
  font-family: serif;
  font-weight: 100;
}

.loginformCont {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.loginformCont h2 {
  font-size: 4.4rem;
  color: #000;
  font-weight: 500;
}
.loginformCont h3 {
  font-size: 2.6rem;
  color: #000;
}

.loginInputWrap {
  display: flex;
  gap: 0.8rem;
  border-radius: 9rem;
  border: 1px solid #ddd;
  overflow: hidden;
  padding: 0 0.9rem;
  align-items: center;
  padding-right: 0rem;
}
.loginForm button,
.loginForm input {
  color: #999;
  padding: 1.1rem 2rem;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 200;
  border: none;
}
.loginForm button {
  border-radius: 9rem;
  border: 1px solid #ddd;
  background-color: #2d5ecf;
  color: #fff;
  transition: all .3s;
}
.loginForm button:hover, 
.loginForm button:active {
  background-color: #36bb8a;
  color: #fff;
}
.loginForm input:hover, 
.loginForm input:active {
  background-color: transparent; 
}

.formButtom {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  font-size: 1.8rem;
}


.lgSnp:link, .lgSnp:visited {
  color: #f35e6a;
}
.lgSnp:hover, .lgSnp:active {
  box-shadow: 0rem .1rem  0 0 #f35e6a;
}



/**************************/
/* BELOW 1129px (Landscape Tablets) */
/**************************/


@media (max-width: 70.5em) {
  .loginForm h4 { 
    font-size: 3rem; 
}

.loginForm {
  padding: 4.4rem 5rem; 
}
  
}



/**************************/
/* BELOW 928px (Landscape Tablets) */
/**************************/

@media (max-width: 58em) {
  .loginCont { 
    width: 90%;
  
}

}



/**************************/
/* BELOW 544px (Phones) */
/**************************/
@media (max-width:34em ) {

  .loginDiscription.arm {
    display: none;
    
}

.loginCont {
  width: 40%;
  background-color: #fff;
}

.loginCont {
  width: 79%; 
}

.loginformCont {
  width: 100%;
  font-size: 1.4rem;
}

.loginForm {
  padding: 4.4rem 8rem;
}

.loginForm div:first-child{
  margin-bottom: 0 !important;
}

}



/**************************/
/* BELOW 433px (Phones) */
/**************************/
@media (max-width: 27em) {
  .loginForm {
    padding: 4.4rem;
}

.loginForm h4 {
  font-size: 2.5rem;
}

.loginForm button, .loginForm input { 
  padding: 1.1rem 1rem; 
}
  
}
   
/**************************/
/* BELOW 300px (Phones) */
/**************************/

/* @media (max-width: 218.7em) {

  .loginForm {
    padding: 2.4rem;
}
  
} */