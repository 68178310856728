@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root{
  --primary-color: #0DFF92;
  --dark-color:#222222;
  --light-color: #f0f0f0;
}


* > *{
    font-family: 'Poppins', sans-serif;
}

.quizeCont{
  position: relative;
  height: 100%;
  color: #333;
  font-size: 1.5rem;
  /* margin: 40px auto; */
  /* width: 60%; */
  
}

.quizeCont .title{
    font-size: 3em;
    text-align: center;
    border: 5px solid var(--primary-color);
    padding: .3em .2em;
    border-radius: 4px;
}

.text-light {
	color: var(--light-color)
}

.quizeCont ul{
  list-style: none;
  margin: 0;
  padding: 0;
	/* overflow: auto; */
}




/* 
.quizeCont .questions .qid{
  padding: .2em .7em;
  color: #222222;
  background-color: #0DFF92;
  border-radius: 50px;
} */

.quizeCont .grid{
    
    display: grid;
    grid-template-columns: 1fr 1fr;
}


.quizeCont .btn{
    padding: .2em 1.7em;
    border: none;
    border-radius: .1em;
    font-size: 1.2em;
}

.quizeCont .btn:hover{
    cursor: pointer;
    background-color: #f0f0f0;
    color: #202020;
}

.next{
    background-color: var(--primary-color);
    justify-self: flex-end;
}

.prev{
    background-color: #008cba;
    justify-self: flex-start;
}

.quizeCont ul li{
  color: #333;
  position: relative;
  float: left;
  width: 100%;
  /* display: block; */
  /* height: 100px; */
	/* border-bottom: 1px solid #333; */
}

.quizeCont ul li input[type=radio]{
  position: absolute;
  visibility: hidden;
}

.radioPosition{
  position: relative;
}
.quizeCont ul li label{
 
  font-size: 1.8rem;
  font-weight: 100;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  /* padding: 25px 25px 25px 80px; */
  /* margin: 10px auto; */
  /* height: 30px; */
  /* display: block; */
  /* font-weight: 300; */


}

.quizeCont ul li:hover label{
	color: red;
}

.quizeCont ul li .check{
  display: block;
  position: absolute;
  border: 5px solid #AAAAAA;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: 0rem;
  left: 0rem;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}



.quizeCont ul li .check.withLatter{
  display: block;
  position: absolute;
  border: 5px solid #AAAAAA;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: -0.2rem !important;
  left: -3.9rem !important;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}

.quizeCont ul li .lable {
  margin-left: 5rem;
}

input[type=radio]:checked ~ .label .check {
  /* border: 5px solid var(--primary-color); */
  border: 5px solid #a92dd2;
}

.quizeCont ul li:hover .checked {
  border: 5px solid #FFFFFF;
}

.quizeCont ul li .check::before {
  display: block;
  position: absolute;
	content: '';
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
  background-color: #fff;
  /* border: 1px solid transparent; */
}


input[type=radio]:checked ~ .check {
  /* border: 5px solid var(--primary-color) */
  border: 5px solid #a92dd2;
}

input[type=radio]:checked ~ .check::before{
  /* background: var(--primary-color) */
  background: #a92dd2;
  box-shadow: inset 0 0 0 .5rem rgb(255, 255, 255);
}

input[type=radio]:checked ~ .text-primary{
  /* color: var(--primary-color) */
  color: #a92dd2;
} 

/* To get selected option we are using this checked class */
.checked {
  /* border: 5px solid var(--primary-color) !important; */
  border: 5px solid #04aa6d !important;
}

.checked::before{
  background: var(--primary-color)
}

.resultsCont {
padding: 4.5rem;
}
.contR {
  width: 80%;
}
.resultsCont .result {
  background-color: #fafaff;
  padding: 2.4rem;
  border: 1px solid #333;
}