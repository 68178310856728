.sectionTerms {  
  font-size: 2.1rem;
  background-color: #fff;
}
.mainCont {
  width: 80%;
}
.termsCont { 
  background-color: #fafaff;
  padding: 9.5rem;
  box-shadow: 1rem 1rem 2rem 0 rgba(0, 0, 0, 0.35);
  line-height: 1.5;
  border-radius: 1.9rem
  ;
}