.cbtContainer {
  height: 100%;
  background-color: greenyellow;
  background-image: linear-gradient( to right bottom,
      rgba(0, 0, 128, 0.637),
      rgba(255, 0, 0, 0.507)
    ),
    url(./cbtimages/military.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5.6rem; 
  position: relative;
}

.rdtDasb:link, .rdtDasb:visited {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.2rem;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
}

.cbtHeads {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0rem;
  flex-direction: column;
  color: #fff;
}

.cbtHeads h2 {
  font-size: 5.5rem;
  text-align: center;
}
.cbtHeads h3 {
  text-align: center;
  font-size: 6.8rem;
  font-weight: 100;
  margin-top: -1rem;
}
.cbtHeads p {
  font-size: 1.7rem;
  font-weight: 100;
  text-align: center;
  line-height: 1.5;
}

 
.forceSelect.cbtSlideConterTwo{
  height: 30rem; 
}

.forceSelect a:link,
.forceSelect a:visited {
  /* width: 20%; */
  display: flex;
  flex-direction: column;
  gap: .8rem;
  background-color: rgba(3, 3, 63, 0.829);
  padding: 1.4rem;
  border-radius: 0.6rem;
  transition: all 0.3s;
  cursor: pointer;
  text-align: center;
  color: #fff;
  font-size: 1.2rem;
}

.forceSelect a:hover,
.forceSelect a:active {
    background-color: rgba(243, 243, 243, 0.712);
    color: navy;
    /* transform: scale(1.05); */
}
/* Swiper js dec */
.forceSelect .swiper-slide {
  background: transparent;
} 
.forceSelect .swiper-button-next, .forceSelect .swiper-button-prev{
  background-color: rgb(59, 59, 131); 
}
.forceSelect .swiper-button-next:hover, .forceSelect .swiper-button-prev:hover{
  background-color: #aa2c2c8f;
  color: #666;
}
 
/* _______________________________ */
.flogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  justify-self: center;
}

.flogo img {
  width: 50%;
  height: 10rem;
}
