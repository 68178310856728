
/* Funding Payment methods  */
.paymentMethods {
    display: grid;
    background-color: #fff;
    grid-template-columns: 3fr 1.3fr;
    /* border: 1px solid #eee; */
    border-radius: 1.2rem;
    width: 70%;
    margin: 0 auto;
    margin-top: 4.5rem; 
    overflow: hidden;
  }
  


  .paymentDisplay {
    padding: 4.4rem;
  }
  
  .methods:link,
  .methods:visited {
    color: #999;
  }
  
  .iconMth {
    color: #ccc;
    transform: scale(1.5);
  }
  
  .paymentItems h6 {
    color: #4c3100;
    font-size: 1rem;
  }
  
  .paymentItems ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    justify-content: flex-start;
    background-color: #fff;
    padding: 3.4rem;
    padding-right: 2rem; 
  }
  .ChequeCompCont {
    border-radius: 1.2rem;
    box-shadow: .8rem .8rem 2rem rgba(0, 0, 0, 0.35); 
    padding: 4.5rem;
  }
  .paymentItems ul li {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  
  .AirtimeEntCont {
    border: 1px solid #ccc;
    border-radius: 1.2rem;
    padding: 5.4rem;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    background-color: #fff;
  }
  
  .airtimePay {
    position: relative;
    padding: 5.4rem;
    margin: 0 auto;
    gap: 1.4rem;
    background-color: #fdf2e9;
    font-size: 1.8rem;
    border-radius: 1.2rem; 
    overflow: hidden;
  } 

  .airtimePay::after {
    content: "Best Value";
    padding: .5rem;
    background-color: #ffd43b;
    color: #333;
    width: 20rem;
    position: absolute;
    top: 41px;
    right: -47px;
    transform: rotate(45deg);
    text-align: center;
  }

  .airtimePay h2 {
    color: #cf711f;
    font-size: 2.8rem;
  }

  
  /* .aPCard div {
    padding: 1.2rem 2.4rem;
  } */
  
  .aPCar
  .aPCard {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    border: 1px solid #eee; 
  }
  
  .aPCard h4 {
    font-size: 1.4rem;
    color: #806533;
  }
  .aPCard p{
    font-size: 1.8rem;
    font-weight: 100;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    columns: #333;
    /* font-style: italic; */
     
  }
  
  .aPbottom {
    background-color: #ffffff;
    color: #999;
    font-size: 1.2rem;
    border-top: 1px solid #eee;
    padding: 1.2rem;
  }
  
  .aPBtn:link,
  .aPBtn:visited {
    width: 60%;
    background-color: #cf711f;
    color: #666;
    padding: 0.8rem;
    border-radius: 0.6rem;
    text-align: center;
    font-weight: 700;
    transition: all .3s;
  }
  
  .aPBtn:hover,
  .aPBtn:active{
    opacity: .8;
  }
  #aPAmount  {
    width: 100%;
    background-color: #fafafa;
    color: #999;
    border: 1px solid #ddd;
    padding: 1.2rem;
    margin-top: 1rem;
  }
  
  .apNetwork {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    margin-top: 2.8rem;
    /* gap: 1.4rem; */
  }
  
 
  .AirtimeEntCont .apNetwork {
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .apNetwork h4 {
    font-size: 2rem;
    font-weight: 200;
  }
  
  .bankTransfer {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  
  .bankTransfer .apNetwork {
    gap: 0;
  }
  
  #enBnk {
    display: flex;
  
    flex-direction: column;
    gap: 2.4rem;
  }
  
  #enBnk input,
  #enBnk button {
    width: 100%;
    padding: 0.5rem;
    font-size: 1.8rem;
    border: none;
    font-weight: 100;
    overflow: hidden;
    padding: 1.2rem;
  }
  
  #enBnk input {
    background-color: #fafaff;
    border: 1px solid #bfd0ed;
  }
  #enBnk button {
    background-color: #2961c4;
    color: #fff;
    transition: all .3s;
  }
  #enBnk button:hover,
  #enBnk button:active {
    background-color: #5481d0;
    color: #fff;
  }
  .AirtimeEntCont button {
    width: 100%;
    text-align: center;
    border: none;
    background-color: #2961c4;
    color: white;
    font-size: 1.5rem;
    padding: 1.2rem;
    transition: all .3s;
  }
  
  .AirtimeEntCont button:hover,
  .AirtimeEntCont button:active {
    background-color: #5481d0;
  }
  
  .paymenttitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .8rem;
    margin-bottom: 1.4rem;
    color: #153162;
  }
  #debitCardform {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    font-size: 1.5rem;
  }
  
  #debitCardform button,
  #debitCardform input {
    width: 100%;
    padding: 1.5rem 1.2rem;
    border: 1px solid #eee;
  }
  
  #debitCardform input {
    background-color: #fafaff;
  }
  
  #debitCardform button {
    background-color: #2961c4;
    color: #fff;
    font-size: 1.6rem;
    transition: all .3s;
  }
  #debitCardform button:hover,
  #debitCardform button:active {
    background-color: #5481d0;
  }
  #walletPay {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
  
  #walletPay select,
  #walletPay input {
    width: 100%;
    border: none;
    background-color: #fafaff;
    border: 1px solid #eee;
    padding: 1.2rem 2.4rem;
    font-size: 1.5rem;
    margin-top: 0.8rem;
  }

  .paymentContsB {
    align-items: center;
    /* border-radius: 1.2rem; */
    display: flex;
    justify-content: center;
    margin: 0 auto;
    /* border: 1px solid #ccc; */
    padding: 5.4rem;
    width: 78%;
}

.paymentContsB button {
  background-color: #00d285; 
  color: #fff;
  margin-bottom: 3.2rem;
  padding: 1rem 1.4rem;
  width: 100%;
  border-radius: 1.2rem;
  margin-top: 2.4rem;
}

.paymentContsB button:hover, 
.paymentContsB button:active{
  opacity: .7;
}

.jambFeeProcess{
  font-size: 160.8%;
  background-color: #fafaff;
  color: #666;
  border-radius: 1.2rem;
  padding: 4.5rem;
  width: 82rem;
}
.subjContJamb {
  margin-top: 2.3rem;
  max-width: 100%;
}
.jambFeeProcess select{
  background-color: #fff;
  border-radius: 1.2rem;
  border: 1px solid #ccc;
  padding: 1.6rem 1.2rem;
  margin-right: 2rem;
  width: 25%;
}